import type { App } from 'vue'
import { isObject } from '@sjzy/utils'

export * from './common'
export * from './system'
export * from './is'

const settingsStore = useSettingsStore()
const { language } = storeToRefs(settingsStore)

export function isEN(en: any, other: any) {
  return language.value !== 'zh-CN' ? en : other
}
export function isCN(cn: any, other: any) {
  return language.value === 'zh-CN' ? cn : other
}

export function isZhCn() {
  return language.value === 'zh-CN'
}

// dynamic use hook props
export function getDynamicProps<T, U>(props: T): Partial<U> {
  const ret: Recordable = {}

  Object.keys(props as any).forEach((key) => {
    ret[key] = unref((props as Recordable)[key])
  })

  return ret as Partial<U>
}

export function deepMerge<T = any>(src: any = {}, target: any = {}): T {
  let key: string
  for (key in target) {
    src[key] = isObject(src[key]) ? deepMerge(src[key], target[key]) : (src[key] = target[key])
  }
  return src
}

export function withInstall(component: any, alias?: string) {
  const comp = component as any
  comp.install = (app: App) => {
    app.component(comp.name || comp.displayName, component)
    if (alias) {
      app.config.globalProperties[alias] = component
    }
  }
  return component as any
}
/** 设置参数 */
export function serializeQuery(query: Record<string, any>, isEncode = true) {
  const params = []
  for (const key in query) {
    if (Object.prototype.hasOwnProperty.call(query, key)) {
      const value = query[key]
      if (isEncode)
        params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      else params.push(`${key}=${value}`)
    }
  }
  return params.join('&')
}

export function parseRouteParams(search: string, searchKey: string) {
  const params: Indexable = {}
  const searchParams = new URLSearchParams(search)

  for (const [key, value] of searchParams.entries()) {
    params[key] = value
  }

  return params[searchKey]
}
/** 千分位分隔 */
export function formatNumber(number: number) {
  if (Number.isNaN(number) || !Number.isFinite(number)) {
    return 'Invalid number'
  }
  // 转换为字符串
  const numberStr = number.toString()
  // 检查是否包含小数部分
  const dotIndex = numberStr.indexOf('.')

  // 如果没有小数部分，则直接处理整数部分
  if (dotIndex === -1) {
    return numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  // 如果有小数部分，则分别处理整数部分和小数部分
  const integerPart = numberStr.slice(0, dotIndex)
  const decimalPart = numberStr.slice(dotIndex)

  // 对整数部分添加千分位分隔符
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  // 将处理后的整数部分和小数部分拼接起来
  return formattedIntegerPart + decimalPart
}
