<script lang="ts" setup>
import { SystemGetAuthSystemListByLoginPOST } from '@/api/user-system/System'
import { UserGetCurrentUserBasicInfoPOST } from '@/api/user/User'

defineProps({
  layout: {
    type: String,
    default: '',
  },
  showTabs: {
    type: Boolean,
    default() {
      return true
    },
  },
})

const router = useRouter()
const userStore = useUserStore()
const tabsStore = useTabsStore()
const {
  setSysBusinessType,
  setBusinessSubSystemId,
  setSysBusinessKey,
  setCompanyId,
  setDepartmentId,
  setSystemData,
  setBusinessRoleEnums,
} = userStore
const { businessSubSystemId, userId } = toRefs(userStore)

const typeList = ref<any[]>([])
async function ckFn(n: any) {
  setSysBusinessType(n.sysBusinessType)
  // nameStr.value = n.cnName
  setSysBusinessKey(n.key!)
  setBusinessSubSystemId(n.id!)
  tabsStore.delAllVisitedRoutes()
  await router.push('/')
  location.reload()
}
onMounted(async () => {
  const data = await SystemGetAuthSystemListByLoginPOST({
    isBusinessType: true,
  })
  if (data.code === '200') {
    typeList.value = data.data
    setSystemData(data.data)
    if (!businessSubSystemId.value) {
      setSysBusinessType(data.data[0].sysBusinessType!)
      setBusinessSubSystemId(data.data[0].id!)
      setSysBusinessKey(data.data[0].key!)
    }
  }
  await UserGetCurrentUserBasicInfoPOST({
    userId: userId.value as number,
  }).then((res) => {
    if (res.code === '200') {
      setCompanyId((res.data as API.CurrentUserInfo).companyId!)
      setBusinessRoleEnums((res.data as API.CurrentUserInfo).businessRoleEnums!)
      setDepartmentId((res.data as API.CurrentUserInfo).departmentId!)
    }
  })
})
</script>

<template>
  <div class="sjzy-nav flex-b bg-[var(--sjzy-bg)]">
    <div class="left-panel">
      <LayoutTabsLabel v-show="showTabs" />
    </div>
    <div class="right-panel p-l-10px">
      <LayoutTabsLabelMore v-show="showTabs" />
      <LayoutRefresh class="h-full" />
      <LayoutFullScreen class="h-full" />
      <VabNotice />
      <!-- <LayoutDark /> -->
      <LayoutLanguage class="h-full hover:bg-[#f6f6f6] dark:text-[#fff] dark:hover:bg-[#333]" />
      <ElPopover v-if="typeList.length > 1" placement="top-start" :width="200" trigger="hover">
        <template #reference>
          <div class="ml-20px">
            {{ typeList.find((i) => i.id === businessSubSystemId).cnName }}
            <SjzyIcon
              icon-font="icon-changyong_qiehuan"
              class="mr-5px"
              style="vertical-align: middle"
            />
          </div>
        </template>
        <div class="w-170px">
          <div
            v-for="n in typeList"
            :key="n.id"
            class="cursor-pointer p-l-16px p-r-16px lh-36px"
            :class="{
              'text-[var(--sjzy-color-primary)]': businessSubSystemId === n.id,
              'bg-#F7F7F7': businessSubSystemId === n.id,
            }"
            @click="ckFn(n)"
          >
            {{ n.cnName }}
            <SjzyIcon
              v-if="businessSubSystemId === n.id"
              icon-font="icon-a-duigou2"
              class="float-right mr-5px"
              style="vertical-align: middle"
            />
          </div>
        </div>
      </ElPopover>
      <!-- <LayoutTheme class="h-full" /> -->
      <LayoutAvatar class="h-full hover:bg-[#f6f6f6] dark:text-[#fff] dark:hover:bg-[#333]" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sjzy-nav {
  position: relative;
  height: var(--sjzy-nav-height);
  padding-right: var(--sjzy-base-padding);
  padding-left: var(--sjzy-base-padding);
  overflow: hidden;
  user-select: none;
  box-shadow: var(--sjzy-box-shadow);

  .left-panel {
    display: flex;
    flex: 1;
    place-items: center center;
    min-width: 840px;
    height: var(--sjzy-nav-height);
    overflow: hidden;

    :deep() {
      .fold-unfold {
        margin-right: 6px;
      }
    }
  }

  .right-panel {
    display: flex;
    place-content: center flex-end;
    align-items: center;
    width: max-content;
    height: var(--sjzy-nav-height);

    :deep() {
      .icon-btn {
        padding: 0 10px;
        color: var(--el-color-grey);
        cursor: pointer;
      }

      button {
        [class*='ri-'] {
          margin-left: 0;
          color: var(--el-color-white);
          cursor: pointer;
        }
      }
    }
  }
}
</style>
