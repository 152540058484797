import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import type { InitOptions } from '@sjzy/tracing'
// import { init } from '@sjzy/tracing'
import App from './App.vue'
import { setupDirectives } from './directives'
import { router, setupRouter } from './router'
import { setupStore } from './store'
import { setup } from './utils/vab'
import { setupMicroApp } from '@/micro/microInit'
import { setupElementPlus } from '@/plugins/elementPlus'
import { setupSjzyUi } from '@/plugins/sjzyUi'
import { setupI18n } from '@/i18n'
import { setupVxeTable } from '@/plugins/VxeTable'
import { setupLanguage } from '@/plugins/language'
import 'uno.css'
import 'driver.js/dist/driver.css'
import '@/styles/css/global.css'
import '@/styles/scss/main.scss'
import '../iconfont/iconfont.css'
import '../iconfont/iconfont.js'

// 流程面板 图标
import './assets/iconfont/iconfont.css'
import './assets/iconfont/iconfont.js'

async function setupApp() {
  const app = createApp(App)

  const settingsStore = useSettingsStore()
  const { initTheme } = settingsStore
  initTheme()
  function install(_: unknown, _options: InitOptions) {
    // init(options)
  }
  /** createHead */
  app.use(install, {
    dsn: '/trackweb',
    appName: 'f1',
    debug: false,
    pv: true,
    performance: true,
    userUuid: 'userUuid',
    error: true,
    event: true,
    cacheMaxLength: 10,
    cacheWatingTime: 1000,
    // 忽略请求，不记录
    ignoreRequest: [/getAllTracingList/, /cleanTracingList/, /getBaseInfo/, /getSourceMap/],

    // 发送埋点数据后，拉起弹窗提示用户已发送
    // afterSendData(data) {
    //   const { sendType, success, params } = data
    //   console.log(sendType, 'sendType-afterSendData')
    //   console.log(success, 'success-afterSendData')
    //   console.log(params, 'params-afterSendData')
    //   // @ts-ignore
    //   // if (window.getAllTracingList) {
    //   //   // @ts-ignore
    //   //   window.getAllTracingList()
    //   // }
    // },
    // beforeSendData(data) {
    //   const { baseInfo, eventInfo } = data
    //   console.log(eventInfo, 'eventInfo-beforeSendData')
    //   console.log(baseInfo, 'baseInfo-beforeSendData')
    //   return data
    // },
  })
  setup(app)
  /** language */
  setupLanguage(app)
  /** createHead */
  app.use(createHead())
  /** directive */
  setupDirectives(app)
  /** VxeTable */
  setupVxeTable(app)
  /** ElemenPlus */
  setupElementPlus(app)
  /** SjzyUi */
  setupSjzyUi(app)
  /** pinia */
  setupStore(app)
  /** router */
  await setupRouter(app)
  /** i18n */
  setupI18n(app)
  /** mount */
  app.mount('#micro-crm-app')
  /** microapp */
  setupMicroApp(app, router)
}

setupApp()
